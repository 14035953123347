import { useEffect, useState } from 'react'
import InfoChip from '../../atoms/InfoChip/InfoChip'
import { faArrowTrendUp as bull, faArrowTrendDown as bear } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Company } from '../../../types/company'
import { VolumePressure, getVolumePressure } from '../../../utils/company'
import { faThermometerFull as pressureHigh, faThermometerHalf as pressureMed, faThermometerEmpty as pressureLow, faCalendarAlt } from '@fortawesome/free-solid-svg-icons'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/navigation'
import { CompanyEvent } from '../../../types/company'
import { Navigation } from 'swiper'
import './styles.css'

export interface CompanyStatsProps {
	company: Company
}

const CompanyStats = (props: CompanyStatsProps) => {
	const [bullBearIcon, setBullBearIcon] = useState<any>()
	const [bullBearText, setBullBearText] = useState<string>()
	const [volumePressureText, setVolumePressureText] = useState<string>()
	const [volumePressureIcon, setVolumePressureIcon] = useState<any>()

	useEffect(() => {
		if (props.company.fiftyDayAverage > props.company.twoHundredDayAverage) {
			setBullBearIcon(<FontAwesomeIcon icon={bull} size='1x' className='h-5 text-white' />)
			setBullBearText('Bullish')
		} else {
			setBullBearIcon(<FontAwesomeIcon icon={bear} size='1x' className='h-5 text-white' />)
			setBullBearText('Bearish')
		}

		getVolumePressure(props.company.regularMarketVolume, props.company.averageDailyVolume10Day, props.company.averageDailyVolume3Month).then((pressure: string) => {
			switch (pressure) {
				case VolumePressure.HIGH:
					setVolumePressureText('High')
					setVolumePressureIcon(<FontAwesomeIcon icon={pressureHigh} size='1x' className='h-5 text-white' />)
					break
				case VolumePressure.MEDIUM:
					setVolumePressureText('Medium')
					setVolumePressureIcon(<FontAwesomeIcon icon={pressureMed} size='1x' className='h-5 text-white' />)
					break
				case VolumePressure.LOW:
					setVolumePressureText('Low')
					setVolumePressureIcon(<FontAwesomeIcon icon={pressureLow} size='1x' className='h-5 text-white' />)
					break
				default:
					break
			}
		})
	}, [props.company])

	return (
		<div className='flex flex-col w-full'>
			<div className='flex flex-row w-full lg:gap-1 gap-6 justify-between'>
				<div className='w-1/2'>
					<InfoChip title='Sector' text={props.company.sector} />
				</div>
				<div className='w-1/2'>
					<InfoChip title='Market Cap' text={props.company.marketCap} />
				</div>
			</div>

			<div className='flex flex-row w-full lg:gap-0 gap-6 justify-between'>
				<div className='w-full'>
					<InfoChip
						title='Price Trend'
						icon={bullBearIcon}
						text={bullBearText!}
						toolTip={`50 Day Avg. (${Math.round(props.company.fiftyDayAverage)}) is ${bullBearText === 'Bullish' ? 'Above' : 'Below'} the 200 Day Avg. (${Math.round(
							props.company.twoHundredDayAverage,
						)})`}
					/>
				</div>
				<div className='w-full'>
					<InfoChip
						title='Volume Pressure'
						icon={volumePressureIcon}
						text={volumePressureText!}
						toolTip={`Today's Volume (${props.company.regularMarketVolume.toString()}) is ${
							volumePressureText === 'High' ? 'Above' : volumePressureText === 'Low' ? 'Below' : ''
						} Average`}
					/>
				</div>
			</div>

			{props.company.events && props.company.events.length > 0 && (
				<div className='w-full rounded-lg'>
					<div className='flex items-center gap-1 pb-2 mt-1'>
						<FontAwesomeIcon icon={faCalendarAlt} className='h-4 w-4 text-gray-400' />
						<h3 className='text-sm font-medium text-gray-400'>Upcoming Events</h3>
					</div>
					<div className={props.company.events.length === 1 ? 'max-w-md' : ''}>
						<Swiper
							modules={[Navigation]}
							navigation={props.company.events.length > 1}
							spaceBetween={12}
							slidesPerView={1}
							breakpoints={{
								640: { slidesPerView: props.company.events.length > 1 ? 2 : 1 },
							}}
							className='events-swiper'
						>
							{props.company.events.map((event: CompanyEvent, index) => (
								<SwiperSlide key={index}>
									<div className='bg-mslight bg-opacity-60 flex flex-col gap-2 p-2 rounded-lg border border-white/10'>
										<div className={`text-xs font-medium ${event.sensationalScore > 5 ? 'text-yellow-500' : 'text-blue-400'}`}>{event.dateLabel}</div>
										<div className='text-sm text-gray-300 h-[40px] line-clamp-2'>{event.description}</div>
									</div>
								</SwiperSlide>
							))}
						</Swiper>
					</div>
				</div>
			)}
		</div>
	)
}

export default CompanyStats
