import React from 'react'
import { isToday } from 'date-fns'
import { Clock, TrendingUp, TrendingDown, Coins, BarChart2 } from 'lucide-react'

type Company = {
	ticker: string
	name: string
	price: string
	changePercent: string
	volume: string
	recentNews?: {
		generatedData: { headline: string }
		date: string
	}[]
}

type RisersFallers = {
	risers?: Company[]
	fallers?: Company[]
}

interface RisersFallersListProps {
	risersFallers?: RisersFallers
	risersFallersTabSelected: 'risers' | 'fallers'
	handleClickRiserFaller: (ticker: string) => void
}

const RisersFallersList: React.FC<RisersFallersListProps> = ({ risersFallers, risersFallersTabSelected, handleClickRiserFaller }) => {
	return (
		<div className='flex flex-col grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2 py-1'>
			{!risersFallers && <div className='col-span-full text-center py-8'>Loading...</div>}
			{risersFallersTabSelected === 'risers' &&
				risersFallers?.risers?.map((company) => <CompanyCard key={company.ticker} company={company} handleClick={handleClickRiserFaller} isRiser={true} />)}
			{risersFallersTabSelected === 'fallers' &&
				risersFallers?.fallers?.map((company) => <CompanyCard key={company.ticker} company={company} handleClick={handleClickRiserFaller} isRiser={false} />)}
		</div>
	)
}

const Badge: React.FC<{ children: React.ReactNode; variant: 'success' | 'destructive' }> = ({ children, variant }) => {
	const baseClasses = 'text-xs border-4 font-extrabold px-3 py-1 rounded-full shadow-lg flex items-center min-w-[60px] justify-center'
	const variantClasses = variant === 'success' ? 'border-green-500/60 text-white' : 'border-red-400/60 text-white'
	return <span className={`${baseClasses} ${variantClasses}`}>{children}</span>
}

const CompanyCard: React.FC<{ company: Company; handleClick: (ticker: string) => void; isRiser: boolean }> = ({ company, handleClick, isRiser }) => {
	const date = company.recentNews?.[0]?.date ? new Date(company.recentNews[0].date) : new Date()
	const formattedDate = isToday(date) ? 'Today' : formatDateDistance(date)

	return (
		<div
			className='bg-gray-800 border-2 border-white/10 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 cursor-pointer overflow-hidden'
			onClick={() => handleClick(company.ticker.toLowerCase())}
		>
			<div className='p-4'>
				<div className='flex justify-between items-start mb-2'>
					<div>
						<h3 className='text-lg font-semibold dark:text-white'>{company.name}</h3>
						<p className='text-sm dark:text-gray-400'>{company.ticker}</p>
					</div>
					<Badge variant={isRiser ? 'success' : 'destructive'}>{company.changePercent}</Badge>
				</div>
				<div className='flex flex-row justify-between gap-2 mb-4'>
					<div className='flex items-center'>
						<Coins className='w-full h-4 mr-1 text-gray-400' />
						<span className='font-medium dark:text-white'>{company.price}</span>
					</div>
					<div className='flex items-center'>
						<BarChart2 className='w-full h-4 mr-1 text-gray-400' />
						<span className='font-medium dark:text-white'>{company.volume}</span>
					</div>
				</div>
				{company.recentNews && company.recentNews.length > 0 && (
					<div className='bg-gray-700 p-3 rounded-md'>
						<div className='flex items-center text-xs dark:text-gray-400 mb-1'>
							<Clock className='w-3 h-3 mr-1' />
							<span>{formattedDate}</span>
						</div>
						<p className='text-sm dark:text-gray-200'>{company?.recentNews[0]?.generatedData?.headline}</p>
					</div>
				)}
			</div>
		</div>
	)
}

export default RisersFallersList

function formatDateDistance(date: Date): string {
	const now = new Date()
	const diffInSeconds = Math.floor((now.getTime() - date.getTime()) / 1000)

	const secondsInMinute = 60
	const secondsInHour = secondsInMinute * 60
	const secondsInDay = secondsInHour * 24

	if (diffInSeconds < secondsInMinute) {
		const seconds = diffInSeconds
		return `${seconds} second${seconds !== 1 ? 's' : ''} ago`
	} else if (diffInSeconds < secondsInHour) {
		const minutes = Math.floor(diffInSeconds / secondsInMinute)
		return `${minutes} minute${minutes !== 1 ? 's' : ''} ago`
	} else if (diffInSeconds < secondsInDay) {
		const hours = Math.floor(diffInSeconds / secondsInHour)
		return `${hours} hour${hours !== 1 ? 's' : ''} ago`
	} else {
		const days = Math.floor(diffInSeconds / secondsInDay)
		return `${days} day${days !== 1 ? 's' : ''} ago`
	}
}
