import { useState, useEffect } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import CompanyView from './pages/company/index'
import Header from './components/molecules/Header/Header'
import HomeView from './pages/home'
import ComingUpView from './pages/comingup'
import NewsView from './pages/news'
import SignUp from './pages/signup'
import LogIn from './pages/login'
import Reset from './pages/reset'
import { LoggedInAuthResponse } from './services/auth'
import Discord from './pages/discord/Discord'
import About from './pages/about/About'
import ReactGA from 'react-ga4'
import MarketMovers from './pages/market-movers'
import PromptRunner from './pages/prompt-manager'

const TRACKING_ID = 'G-QK8929L6RN' // Replace with your GA4 Measurement ID
ReactGA.initialize(TRACKING_ID)

function App() {
	useEffect(() => {
		ReactGA.send({ hitType: 'pageview', page: window.location.pathname + window.location.search })
	}, [])

	const [isMobileSearchOpen, setIsMobileSearchOpen] = useState(false)
	const [authResult, setAuthResult] = useState<LoggedInAuthResponse | undefined>(undefined)

	useEffect(() => {
		const checkAuth = () => {
			const sessionAuth = localStorage.getItem('sessionAuth')
			if (sessionAuth) {
				const authResult = JSON.parse(sessionAuth) as LoggedInAuthResponse
				setAuthResult(authResult)
			}
		}

		checkAuth()

		window.addEventListener('storage', checkAuth)

		return () => window.removeEventListener('storage', checkAuth)
	}, [])

	return (
		<BrowserRouter>
			<div className='font-sans overflow-hidden bg-msDark'>
				<div style={{ position: 'fixed', top: 0, width: '100%', zIndex: 1000 }}>
					<Header authResult={authResult} setIsMobileSearchOpen={setIsMobileSearchOpen} />
				</div>
				{isMobileSearchOpen && <div className='fixed inset-0 backdrop-blur' style={{ zIndex: 500 }}></div>}
				<div className={isMobileSearchOpen ? 'backdrop-blur' : ''}>
					<Routes>
						<Route path='/' element={<HomeView authResult={authResult} />} />
						<Route path='/signup' element={<SignUp />} />
						<Route path='/login' element={<LogIn />} />
						<Route path='/reset' element={<Reset />} />
						<Route path='/discord' element={<Discord />} />
						<Route path='/market-movers' element={<MarketMovers />} />
						<Route path='/about' element={<About />} />
						<Route path='/company/:market/:ticker' element={<CompanyView />} />
						<Route path='/news/comingup' element={<ComingUpView />} />
						<Route path='/news/:market/:ticker/:id' element={<NewsView />} />
						{/* Management */}
						<Route path='/admin/prompt-manager' element={<PromptRunner />} />
					</Routes>
				</div>
			</div>
		</BrowserRouter>
	)
}

export default App
