import React, { useState } from 'react'
import { getRnsPrompt } from '../../services/admin'

interface PromptData {
	prompt: string
	system: string
	model: string
	apiKey: string
}

const PromptRunner = () => {
	const [rnsID, setRnsId] = useState('')
	const [loading, setLoading] = useState(false)
	const [error, setError] = useState('')
	const [promptData, setPromptData] = useState<PromptData | null>(null)
	const [editedPrompt, setEditedPrompt] = useState('')
	const [editedSystemPrompt, setEditedSystemPrompt] = useState('')
	const [systemCollapsed, setSystemCollapsed] = useState(false)
	const [promptCollapsed, setPromptCollapsed] = useState(false)
	const [copySuccess, setCopySuccess] = useState<string>('')

	const fetchPrompt = async () => {
		if (!rnsID) {
			setError('Please enter a prompt ID')
			return
		}

		setLoading(true)
		setError('')
		try {
			const response = await getRnsPrompt(rnsID)
			if (!response) {
				throw new Error('No prompt found')
			}
			setPromptData(response)
			setEditedPrompt(response.prompt.trim())
			setEditedSystemPrompt(response.system.trim())
		} catch (err) {
			setError((err as Error).message)
		} finally {
			setLoading(false)
		}
	}

	const copyToClipboard = async (text: string, type: string) => {
		try {
			await navigator.clipboard.writeText(text)
			setCopySuccess(`${type} copied!`)
			setTimeout(() => setCopySuccess(''), 2000)
		} catch (err) {
			setCopySuccess('Failed to copy')
		}
	}

	return (
		<div className='container mx-auto px-5 font-sans min-h-screen pt-[100px]'>
			<div className='flex gap-3 mb-6'>
				<input
					type='number'
					placeholder='Enter Prompt ID'
					value={rnsID}
					onChange={(e) => setRnsId(e.target.value)}
					className='w-48 px-3 py-2 border border-gray-300 rounded-md'
				/>
				<button
					onClick={fetchPrompt}
					disabled={loading}
					className={`px-4 py-2 rounded-md text-white ${loading ? 'bg-gray-400 cursor-not-allowed' : 'bg-blue-500 hover:bg-blue-600 cursor-pointer'}`}
				>
					{loading ? 'Loading...' : 'Load Prompt'}
				</button>
				{copySuccess && <span className='text-green-600 py-2'>{copySuccess}</span>}
			</div>

			{error && <div className='p-3 mb-6 bg-red-100 text-red-600 rounded-md'>{error}</div>}

			{promptData && (
				<div className='space-y-6 bg-white rounded-lg p-6 shadow-md'>
					<div>
						<div className='flex items-center justify-between mb-2'>
							<div className='flex items-center gap-4'>
								<label className='font-medium'>System Prompt</label>
								<button onClick={() => copyToClipboard(editedSystemPrompt, 'System prompt')} className='text-blue-500 hover:text-blue-700 text-sm'>
									Copy to clipboard
								</button>
							</div>
							<button onClick={() => setSystemCollapsed(!systemCollapsed)} className='text-gray-500 hover:text-gray-700'>
								{systemCollapsed ? 'Expand' : 'Collapse'}
							</button>
						</div>
						{!systemCollapsed && (
							<textarea
								value={editedSystemPrompt}
								onChange={(e) => setEditedSystemPrompt(e.target.value)}
								className='w-full h-64 px-3 py-2 border border-gray-300 rounded-md resize-y'
							/>
						)}
					</div>

					<div>
						<div className='flex items-center justify-between mb-2'>
							<div className='flex items-center gap-4'>
								<label className='font-medium'>Prompt</label>
								<button onClick={() => copyToClipboard(editedPrompt, 'Prompt')} className='text-blue-500 hover:text-blue-700 text-sm'>
									Copy to clipboard
								</button>
							</div>
							<button onClick={() => setPromptCollapsed(!promptCollapsed)} className='text-gray-500 hover:text-gray-700'>
								{promptCollapsed ? 'Expand' : 'Collapse'}
							</button>
						</div>
						{!promptCollapsed && (
							<textarea value={editedPrompt} onChange={(e) => setEditedPrompt(e.target.value)} className='w-full h-96 px-3 py-2 border border-gray-300 rounded-md resize-y' />
						)}
					</div>

					<div className='text-sm text-gray-500'>Model: {promptData.model}</div>
				</div>
			)}
		</div>
	)
}

export default PromptRunner
