import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { getCompanyNews } from '../../services/rns'
import { Rns } from '../../types/rnsList'
import { NewsModel } from '../../components/molecules/NewsModel/NewsModel'
import { Icon } from '../../components/atoms/Icon/Icon'
import Button from '../../components/atoms/Button/Button'

const NewsView = () => {
	const [loading, setLoading] = useState<boolean>(false)
	const [loadingText, setLoadingText] = useState<string>('Loading...')
	const { market = '', ticker = '', id = '' } = useParams()
	const [newsItem, setNewsItem] = useState<Rns>()
	const navigate = useNavigate()

	const checkNewsStatus = async () => {
		const res = await getCompanyNews(market, ticker, id)
		if (!res) return false
		if (res.processing) {
			setLoadingText('Processing news...')
			setNewsItem(res)
			return false
		}
		setNewsItem(res)
		return true
	}

	useEffect(() => {
		const startProcessing = async () => {
			setLoading(true)
			setLoadingText('Loading...')

			const checkAndAnimate = async () => {
				const isComplete = await checkNewsStatus()
				if (!isComplete) {
					setTimeout(checkAndAnimate, 5000)
				} else {
					setLoading(false)
				}
			}

			checkAndAnimate()
		}

		startProcessing()

		return () => {
			setLoading(false)
		}
	}, [id])

	const handleNavigateToCompany = () => {
		navigate(`/company/lse/${ticker}`)
	}

	const handleClickViewRns = () => {
		window.open(`https://www.londonstockexchange.com/stock/${ticker.toUpperCase()}/xx/analysis`, '_blank', 'noreferrer')
	}

	if (loading || newsItem?.processing) {
		return (
			<div className='min-h-screen bg-msDark flex items-center justify-center p-6'>
				<div className='max-w-lg w-full bg-opacity-50 backdrop-blur-sm bg-mslight rounded-xl p-8'>
					{/* Header Section */}
					<div className='flex flex-col items-center space-y-4 mb-8'>
						<div className='text-white font-bold text-2xl tracking-wide'>{ticker.toUpperCase()}</div>
						<div className='text-gray-300 text-base text-center font-medium'>{newsItem?.headline || 'Loading news...'}</div>
					</div>

					{/* Loading Section */}
					<div className='flex flex-col items-center space-y-6 mb-8'>
						<Icon type='iconAnimation' className='w-16 h-16' />

						<div className='text-white font-medium text-lg'>{loadingText}</div>

						{/* Progress bar */}
						<div className='w-full max-w-md bg-msDark rounded-full h-1.5 overflow-hidden'>
							<style>{`
								@keyframes continuousProgress {
									0% { transform: translateX(-100%); }
									100% { transform: translateX(100%); }
								}
							`}</style>
							<div
								className='h-full bg-blue-500'
								style={{
									animation: 'continuousProgress 2s linear infinite',
									width: '100%',
								}}
							/>
						</div>
					</div>

					{/* Action Buttons */}
					<div className='flex flex-col sm:flex-row gap-4 justify-center'>
						<Button
							text='View RNS'
							onClick={handleClickViewRns}
							className='bg-msDark hover:bg-opacity-80 transition-all duration-200 font-semibold rounded-lg px-8 py-3 text-white min-w-[140px] text-center'
						/>
						<Button
							text='View Company'
							onClick={handleNavigateToCompany}
							className='bg-blue-600 hover:bg-blue-700 transition-all duration-200 font-semibold rounded-lg px-8 py-3 text-white min-w-[140px] text-center'
						/>
					</div>
				</div>
			</div>
		)
	}

	return (
		<div className='min-h-screen overflow-auto font-sans text-white bg-msDark'>
			<NewsModel
				isOpen={true}
				title={newsItem?.generatedData ? newsItem.generatedData.headline : newsItem?.headline}
				originalTitle={newsItem?.headline}
				url={newsItem?.url}
				dateTime={newsItem?.date ?? undefined}
				longSummary={newsItem?.generatedData ? newsItem?.generatedData.longSummary : newsItem?.headline}
				bulletPoints={newsItem?.generatedData ? newsItem?.generatedData.bulletPoints : undefined}
				handleClose={handleNavigateToCompany}
				handleNavigateToCompany={handleNavigateToCompany}
				newsId={newsItem?.id}
				ticker={newsItem?.company.ticker}
				companyIndustry={newsItem?.company?.industry}
				companyMarketCap={newsItem?.company?.marketCap}
				companyMarketCapDisp={newsItem?.company?.marketCapDisp}
				keyDates={newsItem?.generatedData?.keyDates}
				sentimentScore={newsItem?.generatedData?.sentimentScore}
				sentimentReasoning={newsItem?.generatedData?.sentimentReasoning}
				companyDetail={newsItem?.generatedData?.companyDetail}
				hideCloseButton={true}
				showHomeButton={true}
				financials={newsItem?.generatedData?.financials}
				id={newsItem?.id}
			/>
		</div>
	)
}

export default NewsView
