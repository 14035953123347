import { gql } from '@apollo/client'
import { getServiceClient } from './base'
import { BrokerTargets, Company, CompanyPrice, MarketPrice, RisersFallers } from '../types/company'
import { Trade } from '../components/organisms/TradesView/TableRow/TableRow'

export async function getBrokerTargets(ticker: string): Promise<Array<BrokerTargets>> {
	let brokerTargets: Array<BrokerTargets> = []
	try {
		const client = await getServiceClient('company')
		const resp = await client.query({
			query: gql`query {
							getCompanyBrokerTargets(ticker: "${ticker}") {
								brokerChange
								brokerName
								date
								newPriceTarget
								newRecommendation
								oldPriceTarget
								price
							}
						}`,
		})
		brokerTargets = resp.data.getCompanyBrokerTargets as Array<BrokerTargets>
	} catch (error) {
		console.error(`Error getting Broker Targets`, error)
	}

	return brokerTargets
}

export async function getCompany(market: string, ticker: string): Promise<Company> {
	let company: Company = {} as any
	try {
		const client = await getServiceClient('company')
		const resp = await client.query({
			query: gql`query {
            getCompany(market: "${market}", ticker: "${ticker}") {
              name
              ticker
              currency
              description
              currentPrice
              currentPriceChange
              currentPriceChangePercent
			  currentPriceTime {
				raw
				fmt
			  }
              marketCap
			  fiftyTwoWeekHigh
			  fiftyTwoWeekLow
              sector
              industry
              fiftyDayAverage
              twoHundredDayAverage
              averageDailyVolume3Month
              averageDailyVolume10Day
              regularMarketVolume
			  events {
				date
				dateLabel
				description
				sensationalScore
				sourceNewsId
              }
            }
          }`,
		})
		company = {
			...(resp.data.getCompany as Company),
		}
	} catch (error) {
		console.error(`Error getting Company`, error)
	}

	return company
}

export async function getCompanyTrades(market: string, ticker: string): Promise<Trade[]> {
	let trades: Trade[] = {} as any
	try {
		const client = await getServiceClient('company')
		const resp = await client.query({
			query: gql`
				query {
					getCompanyTrades(market: "${market}", ticker: "${ticker}") {
						type
						price
						volume
						dateTime
					}
				}
			`,
		})
		trades = resp.data.getCompanyTrades as Trade[]
	} catch (error) {
		console.error(`Error getting Company`, error)
	}
	return trades
}

export async function searchCompany(query: string): Promise<Array<{ ticker: string; name: string }>> {
	let results: Array<{ ticker: string; name: string }> = {} as any
	try {
		const client = await getServiceClient('company')
		const resp = await client.query({
			query: gql`
				query {
					searchCompany(query: "${query}") {
						name
    					ticker
					}
				}
			`,
		})
		// console.log(`graph`, JSON.stringify(resp.data))
		results = resp.data.searchCompany
	} catch (error) {
		console.error(`Error getting Company`, error)
	}
	return results
}

export async function getCompanyPrices(
	market: string,
	ticker: string,
	range: string,
): Promise<
	Array<{
		volume: string
		x: string
		y: string
	}>
> {
	let prices: Array<{
		volume: string
		x: string
		y: string
	}> = [] as any
	try {
		console.log('hh', range)
		const client = await getServiceClient('company')
		const resp = await client.query({
			query: gql`
				query {
					getCompanyPriceHistory(market: "${market}", ticker: "${ticker}", range: "${range}") {
						historicPrices {
							volume
							x
							y
						}
					}
				}
			`,
		})
		prices = resp.data.getCompanyPriceHistory.historicPrices
	} catch (error) {
		console.error(`Error getting Company prices`, error)
	}
	return prices
}

export async function getCompanyPrice(market: string, ticker: string): Promise<CompanyPrice> {
	let companyPrice: CompanyPrice = {} as any
	try {
		const client = await getServiceClient('company')
		const resp = await client.query({
			query: gql`
				query {
					getCompanyPrice(market: "${market}", ticker: "${ticker}") {
						currency 
						currentPriceChangePercent
						currentPrice
						currentPriceChange
						currentPriceTime {
							raw
							fmt
						}
					}
				}
			`,
		})
		companyPrice = resp.data.getCompanyPrice as CompanyPrice
	} catch (error) {
		console.error(`Error getting Company`, error)
	}
	return companyPrice
}

export async function getMarketPrices(): Promise<MarketPrice[]> {
	let marketPrices: MarketPrice[] = []
	try {
		const client = await getServiceClient('company')
		const resp = await client.query({
			query: gql`
				query {
					getMarketPrices {
						name
						price
						priceChange
						changePercent
						priceTime
						state
						symbol
						currency
					}
				}
			`,
		})
		marketPrices = resp.data.getMarketPrices as MarketPrice[]
	} catch (error) {
		console.error(`Error getting Company`, error)
	}
	return marketPrices
}

export async function getRisersFallers(): Promise<RisersFallers | undefined> {
	try {
		const client = await getServiceClient('company')
		const resp = await client.query({
			query: gql`
				query GetRisersFallers {
					getRisersFallers {
						fallers {
							changePercent
							name
							price
							priceChange
							ticker
							volume
							recentNews {
								generatedData {
									headline
								}
							}
						}
						risers {
							changePercent
							name
							priceChange
							price
							ticker
							volume
							recentNews {
								date
								generatedData {
									headline
								}
							}
						}
					}
				}
			`,
		})
		return resp.data.getRisersFallers as RisersFallers
	} catch (error) {
		console.error(`Error getting Riser fallers`, error)
	}
	return undefined
}
