import { useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { getCompanyPrice } from '../../../services/company'
import { CompanyPrice } from '../../../types/company'
import { Icon } from '../../atoms/Icon/Icon'
import ShareMenu from '../ShareMenu/ShareMenu'
import MoreMenu from '../MoreMenu/MoreMenu'
import { Rns } from '../../../types/rnsList'
import { mapMarketCap } from '../../../utils/company'
import { Bookmark } from '../../atoms/Bookmark/Bookmark'
import { LoggedInAuthResponse } from '../../../services/auth'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination, Autoplay } from 'swiper'
import { RnsDocumentTypes } from '../../../types/rnsList'
import {
	Cash,
	Chart,
	Coins,
	Director,
	FirstQuater,
	FullYear,
	HalfYear,
	HandShake,
	LegalDocument,
	Megaphone,
	MoneyBag,
	Pound,
	ReceiveMoney,
	Trophy,
} from '../../atoms/Icons/Icons'
import { addOneHourForBST } from '../../../utils/utils'
import ClockIcon from './icons/clock'

type SentimentScore = 'UNKNOWN' | 'VERY_BAD' | 'BAD' | 'NEUTRAL' | 'GOOD' | 'VERY_GOOD'

export interface NewsCardProps {
	news?: Rns
	onClick?: any
	showPrice?: boolean
	showCompanyDetail?: boolean
	loading?: boolean
	authResult?: LoggedInAuthResponse
	isBookmarked?: boolean
	onBookmarkClick?: any
	isRead?: boolean // New prop
}

const today = new Date()

const NewsCard = (inputProps: NewsCardProps) => {
	const [sentimentColour, setSentimentColour] = useState<string>()
	const [marketCapStyle, setMarketCapStyle] = useState<{ background: string; text: string }>()
	const [companyPrice, setCompanyPrice] = useState<CompanyPrice>()
	const [showShareMenu, setShowShareMenu] = useState(false)
	const [showMoreMenu, setShowMoreMenu] = useState(false)
	const [isBookmarked, setIsBookmarked] = useState<boolean>(inputProps?.isBookmarked || false)

	const shareMenuRef = useRef<HTMLDivElement | null>(null)
	const showMoreMenuRef = useRef<HTMLDivElement | null>(null)
	const navigate = useNavigate()

	function renderPriceItems() {
		return (
			<div className='flex flex-row w-full text-xs'>
				<span>{companyPrice?.currentPriceChangePercent}</span>
			</div>
		)
	}

	const toggleBookmark = () => {
		if (
			inputProps.onBookmarkClick(
				{
					rnsId: inputProps.news!.id,
					ticker: inputProps.news!.company.ticker,
					market: 'lse',
					newsDate: inputProps.news?.date,
				},
				isBookmarked,
			) === true
		) {
			setIsBookmarked(!isBookmarked)
		}
	}

	const handleShareButtonClick = () => {
		setShowShareMenu((prevShow) => !prevShow)
	}

	const handleMoreMenuClick = () => {
		setShowMoreMenu((prevShow) => !prevShow)
	}

	const handleClick = () => {
		inputProps.onClick(inputProps.news)
	}

	const handleTickerClick = () => {
		navigate(`/company/lse/${inputProps.news?.company?.ticker?.toLowerCase()}`)
	}

	const newsDateTime = new Date(inputProps?.news?.date as string)
	let isToday = false
	if (newsDateTime) {
		isToday = today.getFullYear() === newsDateTime.getFullYear() && today.getMonth() === newsDateTime.getMonth() && today.getDate() === newsDateTime.getDate()
	}

	const isTodayOrLater = (timestamp: number) => {
		const dateFromTimestamp = new Date(timestamp * 1000) // Convert timestamp to milliseconds
		return dateFromTimestamp >= newsDateTime
	}

	useEffect(() => {
		const marketCapStyles: { [key: string]: { background: string; text: string } } = {
			'Large Cap': { background: 'bg-blue-500', text: 'text-black' }, // Example color
			'Mid Cap': { background: 'bg-green-500', text: 'text-black' }, // Example color
			'Small Cap': { background: 'bg-yellow-500', text: 'text-black' }, // Example color
			'Micro Cap': { background: 'bg-red-500', text: 'text-white' }, // Example color
		}

		const style = marketCapStyles[mapMarketCap(inputProps.news?.company?.marketCap) as keyof typeof marketCapStyles]
		if (style) {
			setMarketCapStyle(style)
		}
	}, [inputProps.news?.company?.marketCap])

	const documentTypeIcon = (documentType: RnsDocumentTypes) => {
		switch (documentType) {
			case RnsDocumentTypes.ANNUAL_RESULTS:
				return <FullYear />
			case RnsDocumentTypes.CONTRACT_WIN:
				return <HandShake />
			case RnsDocumentTypes.DIRECTOR_DEALINGS:
				return <Director />
			case RnsDocumentTypes.GENERAL_UPDATE:
				return <Megaphone />
			case RnsDocumentTypes.HALF_YEAR:
				return <HalfYear />
			case RnsDocumentTypes.Q1_UPDATE:
				return <FirstQuater />
			case RnsDocumentTypes.TR1:
				return <LegalDocument />
			case RnsDocumentTypes.TRADING_UPDATE:
				return <Chart />
			case RnsDocumentTypes.PLACING:
				return <Coins />
			default:
				return ''
		}
	}

	useEffect(() => {
		if (inputProps.news?.generatedData?.sentimentScore) {
			const sentimentInfo: {
				[key in SentimentScore]: { backgroundColour: string }
			} = {
				UNKNOWN: { backgroundColour: 'bg-gray-500' },
				VERY_BAD: { backgroundColour: 'bg-red-600' },
				BAD: { backgroundColour: 'bg-orange-500' },
				NEUTRAL: { backgroundColour: 'bg-yellow-500' },
				GOOD: { backgroundColour: 'bg-green-500' },
				VERY_GOOD: { backgroundColour: 'bg-blue-500' },
			}
			setSentimentColour(sentimentInfo[inputProps.news.generatedData.sentimentScore as SentimentScore].backgroundColour)
		}
	}, [inputProps.news?.generatedData?.sentimentScore])

	useEffect(() => {
		if (inputProps.showPrice && inputProps.news?.company?.ticker) {
			getCompanyPrice('lse', inputProps.news?.company.ticker).then((price) => {
				setCompanyPrice(price)
			})
		}
	}, [inputProps.news?.company?.ticker])

	return (
		<div className={`shadow relative ${inputProps.isRead ? ' bg-mslight bg-opacity-60' : 'bg-mslight'}`}>
			<div className='flex flex-col w-full gap-1 text-left rounded-t-xl shadow-2xl border-2 border-gray-700'>
				<div onClick={handleClick} className='flex flex-col justify-start p-5 cursor-pointer'>
					<div className='flex justify-between'>
						{inputProps.showCompanyDetail && (
							<div className='flex flex-row justify-left lg:text-xs text-xs items-center gap-1'>
								{inputProps.news?.company?.ticker && (
									<div onClick={handleTickerClick} className='font-bold bg-green-300 border-green-300 text-black px-2 py-1 rounded-xl whitespace-nowrap'>
										{inputProps.news.company.ticker}
									</div>
								)}
								{inputProps.news?.company?.sector && (
									<div className='font-bold bg-blue-600 text-white px-2 py-1 rounded-xl whitespace-nowrap'>{inputProps.news.company.sector}</div>
								)}
								{inputProps.news?.company?.marketCap && (
									<div className={`font-bold ${marketCapStyle?.background} ${marketCapStyle?.text} px-2 py-1 rounded-xl whitespace-nowrap`}>
										<span className='flex flex-row gap-1'>
											<div>{mapMarketCap(inputProps.news.company.marketCap)}: </div>
											<div className=' font-normal'> {inputProps.news.company.marketCapDisp}</div>
										</span>
									</div>
								)}
							</div>
						)}
						{inputProps.showPrice && (
							<div
								className={`text-xs border-4 font-extrabold px-2 py-1 rounded-full shadow-lg flex items-center min-w-[60px] justify-center ${
									companyPrice
										? !isTodayOrLater(companyPrice?.currentPriceTime?.raw)
											? `border-opacity-60 border-slate-700 text-slate-800 bg-gradient-to-r from-blue-300 to-orange-400`
											: companyPrice.currentPriceChangePercent.startsWith('-')
											? 'border-red-400/60 text-white'
											: 'border-green-500/60 text-white'
										: 'border-gray-200 animate-pulse'
								}`}
							>
								{companyPrice ? (
									!isTodayOrLater(companyPrice.currentPriceTime.raw) ? (
										<div className='whitespace-nowrap'>Pre-Open</div>
									) : (
										<div className='xl:text-xs text-sm'>{renderPriceItems()}</div>
									)
								) : (
									<div className='border-gray-200 animate-pulse' />
								)}
							</div>
						)}
					</div>
					<div className='flex flex-col justify-between'>
						<div className='flex flex-col gap-2 py-3 justify-start'>
							{inputProps.loading ? (
								<div className='animate-pulse bg-gray-600 rounded h-5 w-3/4'></div>
							) : (
								<div className={`text-xl font-extrabold min-h-20 ${inputProps.isRead ? ' text-white text-opacity-60' : ' text-white'} `}>
									{inputProps.news?.generatedData?.headline ?? inputProps.news?.headline}
								</div>
							)}
							{inputProps.loading ? (
								<div>
									<div className='animate-pulse bg-gray-600 rounded h-4 my-2'></div>
									<div className='animate-pulse bg-gray-600 rounded h-4 my-2'></div>
									<div className='animate-pulse bg-gray-600 rounded h-4 my-2 w-1/2'></div>
								</div>
							) : (
								<div className={`line-clamp-2 ${inputProps.isRead ? ' text-white text-opacity-60' : ' text-white'}`}>{inputProps.news?.generatedData?.shortSummary ?? ''}</div>
							)}
						</div>
					</div>
				</div>
				<div className='flex flex-row px-3 py-3 justify-between'>
					<div className='flex flex-col justify-end'>
						{inputProps.loading ? (
							<div className='animate-pulse bg-gray-600 rounded h-3 w-1/4 my-2'></div>
						) : (
							<div className='flex flex-row gap-1 align-center text-gray-400 my-2'>
								<div className='flex flex-row gap-1 align-center text-sm'>
									<div className='flex' style={{ alignSelf: 'center' }}>
										<span className='flex flex-row'>
											{inputProps.news?.generatedData?.documentType && <div className='pr-2'>{documentTypeIcon(inputProps.news?.generatedData?.documentType)}</div>}
											<ClockIcon />
										</span>
									</div>
									<div>
										{newsDateTime &&
											addOneHourForBST(newsDateTime).toLocaleString('en-GB', {
												year: 'numeric',
												month: 'long',
												day: '2-digit',
												hour: '2-digit',
												minute: '2-digit',
												weekday: 'short',
												hour12: false,
												timeZone: 'Europe/London',
											})}
									</div>
								</div>
							</div>
						)}
					</div>
					<div className='px-2 flex flex-col justify-end relative'>
						<div className='flex flex-row gap-2'>
							<div className=''>
								{inputProps.loading ? (
									<></>
								) : (
									<button onClick={handleShareButtonClick}>
										<Icon className='h-5' type={'share'} />
									</button>
								)}
								{showShareMenu && (
									<div className={`absolute top-full right-3 z-10`} ref={shareMenuRef}>
										<ShareMenu
											hashtags={`#marketshare #${inputProps.news?.company.ticker?.toLowerCase()} @marketshareai`}
											text={inputProps.news?.generatedData?.headline ?? inputProps.news?.headline}
											url={`${document.location.protocol + '//' + document.location.hostname}/news/lse/${inputProps.news?.company.ticker?.toLowerCase()}/${
												inputProps.news?.id
											}`}
										/>
									</div>
								)}
							</div>
							<div className=''>{inputProps.loading ? <></> : <Bookmark bookmarked={isBookmarked} onClick={toggleBookmark} />}</div>
							<div className=''>
								{inputProps.loading ? (
									<></>
								) : (
									<button onClick={handleMoreMenuClick}>
										<Icon className='h-5' type={'menu_sm'} />
									</button>
								)}
								{showMoreMenu && (
									<div className={`absolute top-full right-3 z-10`} ref={showMoreMenuRef}>
										<MoreMenu
											subject={inputProps.news?.generatedData?.headline ?? inputProps.news?.headline}
											rnsId={inputProps.news?.id}
											ticker={inputProps.news?.company.ticker}
										/>
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className='relative w-full h-1'>
				<div className='border-t-2 border-msMedium border-opacity-10' />
				<div className={`w-full h-1 rounded-b-xl ${sentimentColour}`}></div>
				<div>
					<div className={`absolute top-0 left-0 w-full h-full rounded-b-xl filter blur opacity-90 ${sentimentColour}`}></div>
					<div className={`absolute top-0 left-0 w-full h-full rounded-b-xl filter blur opacity-30 ${sentimentColour}`}></div>
					<div className={`absolute top-0 left-0 w-full h-full rounded-b-xl filter blur opacity-30 ${sentimentColour}`}></div>
				</div>
			</div>
		</div>
	)
}

export default NewsCard
