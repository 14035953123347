import { gql } from '@apollo/client'
import { getServiceClient } from './base'
import { CompanyNewsDate, HomePageNews, ListCompanyRns, NewsFacets, Rns } from '../types/rnsList'
import axios from 'axios'
import { Filters } from '../components/molecules/NewsFilter/NewsFilter'

const clickRegister = 'https://nt7ekmyq33kpfeerlxb77i6mzu0wcaab.lambda-url.eu-west-2.on.aws'

export async function listCompanyRns(market: string, ticker: string, pageSize: number, paginationKey?: { type: string; sk: string }): Promise<ListCompanyRns> {
	let response: ListCompanyRns = {} as any
	try {
		const client = await getServiceClient('rns')
		const paginationPart = paginationKey ? `, paginationKey: { type: "${paginationKey.type}", sk: "${paginationKey.sk}"}` : ''
		const queryString = `query {
            listCompanyRns(market: "${market}", pageSize: ${pageSize}, ticker: "${ticker}"${paginationPart}) {
                paging {
                    lastKey {
                        type
                        sk
                    }
                    totalResults
                }
                items {
                    id
                    date
                    headline
                    url
                    company {
                        industry
                        name
                        marketCap
                        priceChangePercent
                        ticker
                        sector
                    }
                    generatedData {
                        bulletPoints
                        headline
                        longSummary
                        shortSummary
                        sentimentScore
                        sentimentReasoning
                        companyDetail
						financials
						documentType
                        keyDates {
                            date
                            dateLabel
                            description
                        }
                    }
                }
            }
        }`

		const resp = await client.query({
			query: gql(queryString),
		})

		response = resp.data.listCompanyRns as ListCompanyRns
	} catch (error) {
		console.error('Error getting Company RNS', error)
	}

	return response
}

export async function listCompanyNewsDates(market: string, ticker: string): Promise<CompanyNewsDate[]> {
	let response: CompanyNewsDate[] = [] as any
	try {
		const client = await getServiceClient('rns')
		const resp = await client.query({
			query: gql`
				query {
					listCompanyNewsDates(market: "${market}", ticker: "${ticker}") {
						date
						headline
						id
					}
				}
			`,
		})
		response = resp.data.listCompanyNewsDates as CompanyNewsDate[]
	} catch (error) {
		console.error(`Error getting Company RNS dates`)
	}

	return response
}

export async function getCompanyNews(market: string, ticker: string, id: string): Promise<Rns | undefined> {
	let response: Rns = {} as any
	if (!market || !ticker || !id) {
		return undefined
	}
	try {
		const client = await getServiceClient('rns')
		const resp = await client.query({
			query: gql`
				query {
					getCompanyNews(id: "${id}", market: "${market}", ticker: "${ticker}") {
						company {
							industry
							name
							marketCap
							marketCapDisp
							priceChangePercent
							ticker
							sector
						}
						generatedData {
							bulletPoints
							companyDetail
							headline
							keyDates {
								date
								dateLabel
								description
								sensationalScore
							}
							longSummary
							sensationalScore
							sentimentReasoning
							sentimentScore
							shortSummary
							financials
						}
						processing
						date
						headline
						url
						id
					}
				}
			`,
		})
		response = resp.data.getCompanyNews as Rns
	} catch (error) {
		console.error(`Error getting RNS`, error)
	}

	return response
}

export async function registerNewsView(ticker: string, newsId: string): Promise<boolean> {
	try {
		await axios.post(clickRegister, {
			ticker,
			newsId,
		})
		return true
	} catch (error) {
		console.error(`Error registering view`, error)
	}
	return false
}

export async function getNewsItems(items: Array<{ ticker: string; rnsId: string; market: string }>): Promise<HomePageNews[]> {
	let response: HomePageNews[] = [] as any
	try {
		const client = await getServiceClient('rns')

		const newsItems = items.map((i) => {
			return {
				ticker: i.ticker,
				rnsId: i.rnsId,
				market: i.market,
			}
		})
		const resp = await client.query({
			query: gql`
				query GetNewsItems($items: [GetNewsItemsInput!]!) {
					getNewsItems(items: $items) {
						news {
							generatedData {
								headline
								longSummary
								shortSummary
								bulletPoints
								sentimentScore
								sentimentReasoning
								companyDetail
								keyDates {
									date
									dateLabel
									description
								}
							}
							company {
								industry
								marketCap
								priceChangePercent
								sector
								ticker
							}
							date
							id
							url
						}
						views
						weight
					}
				}
			`,
			variables: { items: newsItems },
		})
		response = resp.data.getNewsItems as HomePageNews[]
	} catch (error) {
		console.error(`Error Getting News Items`, error)
	}
	return response
}

export async function getHomePageNews(page: number, filters: Filters): Promise<HomePageNews[]> {
	let response: HomePageNews[] = [] as any
	try {
		const client = await getServiceClient('rns')
		const query = gql`
			query GetHomePageNews($page: Int!, $filters: HomePageFilters!) {
				getHomePageNews(page: $page, filters: $filters) {
					news {
						generatedData {
							headline
							longSummary
							shortSummary
							bulletPoints
							sentimentScore
							sentimentReasoning
							companyDetail
							keyDates {
								date
								dateLabel
								description
							}
							financials
							documentType
						}
						company {
							industry
							marketCap
							marketCapDisp
							priceChangePercent
							sector
							ticker
						}
						headline
						date
						id
						url
					}
					views
					weight
				}
			}
		`
		const resp = await client.query({
			query: query,
			variables: {
				page,
				filters: {
					marketCap: filters.marketCap,
					sentimentOptions: {
						very_good: filters.sentimentOptions.very_good,
						good: filters.sentimentOptions.good,
						neutral: filters.sentimentOptions.neutral,
						bad: filters.sentimentOptions.bad,
						very_bad: filters.sentimentOptions.very_bad,
					},
					todayOnly: filters.todayOnly,
				},
			},
		})

		response = resp.data.getHomePageNews as HomePageNews[]
	} catch (error) {
		console.error(`Error Getting Home News`, error)
	}
	return response
}

export async function getHomePageFacetSummary(): Promise<NewsFacets> {
	let response: NewsFacets = [] as any
	try {
		const client = await getServiceClient('rns')
		const resp = await client.query({
			query: gql`
				query {
					getNewsFacets {
						totalItems
						sentimentCounts {
							VERY_BAD
							BAD
							NEUTRAL
							GOOD
							VERY_GOOD
						}
						facetCounts {
							LargeCap
							MicroCap
							MidCap
							SmallCap
						}
					}
				}
			`,
		})
		response = resp.data.getNewsFacets as NewsFacets
	} catch (error) {
		console.error(`Error Getting Home News`, error)
	}
	return response
}

export async function sendFeedback(newsId: string, ticker: string, feedback: string): Promise<boolean> {
	try {
		const client = await getServiceClient('rns')
		await client.mutate({
			mutation: gql`
				mutation {
					sendFeedback(feedback: "${feedback}", rnsId: "${newsId}", ticker: "${ticker}")
			 	}
			`,
		})
		return true
	} catch (error) {
		console.error(`Error Submitting Feedback`, error)
	}
	return false
}
