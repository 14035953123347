import React, { useEffect, useRef, useCallback } from 'react'

interface InfiniteScrollProps {
	loading: boolean
	hasMore: boolean
	onLoadMore: () => void
	threshold?: number
	children: React.ReactNode
	requiresAuth?: boolean
	isAuthenticated?: boolean
	onAuthRequired?: () => void
	pageNumber: number
	maxUnauthenticatedPages?: number
}

const InfiniteScroll: React.FC<InfiniteScrollProps> = ({
	loading,
	hasMore,
	onLoadMore,
	threshold = 300,
	children,
	requiresAuth = false,
	isAuthenticated = false,
	onAuthRequired,
	pageNumber,
	maxUnauthenticatedPages = 1,
}) => {
	const scrollRef = useRef<HTMLDivElement>(null)

	const handleScroll = useCallback(() => {
		if (loading || !hasMore) return

		// Check authentication requirements
		if (requiresAuth && !isAuthenticated && pageNumber >= maxUnauthenticatedPages) {
			onAuthRequired?.()
			return
		}

		const scrollElement = scrollRef.current
		if (!scrollElement) return

		const scrollPosition = window.innerHeight + window.scrollY
		const thresholdPosition = scrollElement.offsetHeight - threshold

		if (scrollPosition >= thresholdPosition) {
			onLoadMore()
		}
	}, [loading, hasMore, onLoadMore, requiresAuth, isAuthenticated, pageNumber, maxUnauthenticatedPages, onAuthRequired, threshold])

	useEffect(() => {
		window.addEventListener('scroll', handleScroll)
		return () => window.removeEventListener('scroll', handleScroll)
	}, [handleScroll])

	return (
		<div ref={scrollRef}>
			{children}
			{loading && (
				<div className='flex justify-center py-4'>
					<div className='animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-white'></div>
				</div>
			)}
		</div>
	)
}

export default InfiniteScroll
