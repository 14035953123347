import { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons'
import CompanyStats from '../../molecules/CompanyStats/CompanyStats'
import Skeleton from 'react-loading-skeleton'
import PriceChart from '../../atoms/PriceChart'
import { Company } from '../../../types/company'
import { CompanyNewsDate } from '../../../types/rnsList'
import { Clock } from 'lucide-react'

interface CompanyPanelProps {
	company: Company
	companyNewsDate: CompanyNewsDate[] | undefined
}

const formatNumber = (value: string | number): string => {
	const num = typeof value === 'string' ? Number(value) : value
	if (isNaN(num)) return '0'

	if (num >= 1000000000) {
		return (num / 1000000000).toFixed(2) + 'B'
	} else if (num >= 1000000) {
		return (num / 1000000).toFixed(2) + 'M'
	} else if (num >= 1000) {
		return (num / 1000).toFixed(2) + 'K'
	}
	return num.toString()
}

export const CompanyPanel = ({ company, companyNewsDate }: CompanyPanelProps) => {
	const [pricePositive, setPricePositive] = useState(false)
	const [isLoaded, setIsLoaded] = useState(false)

	useEffect(() => {
		setPricePositive(!company.currentPriceChangePercent.startsWith('-'))
	}, [company])

	useEffect(() => {
		setIsLoaded(true)
	}, [])

	// Calculate position in 52-week range
	const getRangePosition = () => {
		const current = parseFloat(company?.currentPrice || '0')
		const low = parseFloat(company?.fiftyTwoWeekLow || '0')
		const high = parseFloat(company?.fiftyTwoWeekHigh || '0')

		if (high === low) return 0
		const position = ((current - low) / (high - low)) * 100
		return Math.min(Math.max(position, 0), 100)
	}

	if (!company) {
		return (
			<div className='w-full p-6'>
				<Skeleton count={3} />
			</div>
		)
	}

	return (
		<div className='relative w-full'>
			<div
				className={`
          max-w-full mb-6 md:mb-0 md:mx-2 pt-2 
          transition-all duration-1000 
          ${isLoaded ? 'opacity-100 p-2 md:bg-gray-800 md:shadow-sm container md:-translate-x-2 md:border-2 border-white/10 rounded-md' : 'opacity-0 translate-y-4'}
        `}
			>
				<div className='grid grid-cols-1 lg:grid-cols-2 gap-2'>
					{/* Company Info Section */}
					<div className='space-y-2 px-2'>
						{/* Company Name and Ticker */}
						<div className='flex flex-col'>
							<h1 className='w-full'>
								<span className='w-full text-5xl md:text-6xl font-bold'>{company.name}</span>
							</h1>
							<span className='font-normal w-full text-slate-400'>Ticker: {company.ticker}</span>
						</div>

						{/* Price Information Section */}
						<div className=''>
							{/* Price and Change */}
							<div className='flex-1'>
								{/* Current Price */}
								<div className={`flex items-baseline gap-2`}>
									<span className='text-4xl font-bold'>{company.currentPrice}</span>
									<span className='text-lg text-white'>{company.currency}</span>
								</div>

								{/* Price Change */}
								<div className='flex items-center gap-3'>
									<div className={`flex items-center gap-2 text-lg ${pricePositive ? 'text-green-400' : 'text-red-400'}`}>
										<span>{company.currentPriceChange}</span>
										<span>({company.currentPriceChangePercent})</span>
										<FontAwesomeIcon icon={pricePositive ? faArrowUp : faArrowDown} className='h-4 w-4' />
									</div>
									<span className='text-sm text-white'>{getTodayText(company.currentPriceTime.raw)}</span>
								</div>
							</div>

							{/* Last Updated */}
							<div className='text-xs text-slate-400/50 flex items-center space-x-1'>
								<Clock width={12} />
								<span>last updated: {company.currentPriceTime.fmt}</span>
							</div>

							{/* 52 Week Range - Compact Version */}
							<div className='w-2/3 bg-msLight/10 rounded-lg'>
								<div className='flex justify-between text-xs mb-1'>
									<span>{formatNumber(company.fiftyTwoWeekLow)}</span>
									<span className='text-gray-400'>52W</span>
									<span>{formatNumber(company.fiftyTwoWeekHigh)}</span>
								</div>
								<div className='relative w-full h-1 bg-gray-700 rounded-full'>
									<div
										className='absolute top-1/2 -translate-y-1/2 h-2 w-1 bg-blue-500 rounded-full'
										style={{
											left: `${getRangePosition()}%`,
											transform: 'translate(-50%, -50%)',
										}}
									/>
								</div>
							</div>
						</div>

						{/* Company Stats */}
						<div className='mt-8'>
							<CompanyStats company={company} />
						</div>
					</div>

					{/* Chart Section */}
					<div className='z-0'>
						<div className='w-full'>
							<PriceChart ticker={company.ticker} companyNewsDate={companyNewsDate} className='w-full' />
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

function getTodayText(timestamp: number): string {
	const inputDate = new Date(timestamp * 1000)
	const today = new Date()
	today.setHours(0, 0, 0, 0)

	if (inputDate.toDateString() === today.toDateString()) {
		return 'today'
	}

	const daysOfWeek = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']
	return daysOfWeek[inputDate.getDay()]
}

export default CompanyPanel
