import React, { useState, useEffect } from 'react'
import { ArrowLeft, Clock, Coins, BarChart2, Loader2 } from 'lucide-react'
import { getMarketPrices, getRisersFallers } from '../../services/company'
import { RiserFallerCompany, RisersFallers } from '../../types/company'
import { isToday } from 'date-fns'

interface CompanyCardProps {
	company: RiserFallerCompany
	isRiser: boolean
	handleClick: (ticker: string) => void
}

const MarketMovers: React.FC = () => {
	const [activeTab, setActiveTab] = useState<'risers' | 'fallers'>('risers')
	const [marketData, setMarketData] = useState<RisersFallers>()
	const [isLoading, setIsLoading] = useState(true)
	const [error, setError] = useState<string | null>(null)

	useEffect(() => {
		const fetchMarketData = async () => {
			try {
				setIsLoading(true)
				setError(null)
				const data = await getRisersFallers()
				if (data) {
					setMarketData(data)
				}
			} catch (err) {
				setError('Failed to load market data. Please try again later.')
				console.error('Error fetching market data:', err)
			} finally {
				setIsLoading(false)
			}
		}

		fetchMarketData()
		// Refresh data every 5 minutes
		const interval = setInterval(fetchMarketData, 5 * 60 * 1000)
		return () => clearInterval(interval)
	}, [])

	const handleBack = () => {
		window.history.back()
	}

	const handleClickRiserFaller = (ticker: string) => {
		// Navigate to company page
		window.location.href = `/company/lse/${ticker.toLowerCase()}`
	}

	return (
		<div className='flex flex-col gap-7 bg-msDark min-h-screen text-white px-4 pb-20 pt-[80px]'>
			{/* Header */}
			<div className='sticky top-0 bg-msDark/95 backdrop-blur-sm z-10 pt-4 pb-2'>
				<div className='max-w-7xl mx-auto w-full'>
					<div className='flex items-center gap-4 mb-6'>
						<button onClick={handleBack} className='hover:bg-white/5 p-2 rounded-lg transition-colors'>
							<ArrowLeft className='h-5 w-5' />
						</button>
						<h1 className='text-2xl font-bold'>Market Movers</h1>
					</div>

					{/* Tabs */}
					<div className='flex gap-2'>
						<button
							onClick={() => setActiveTab('risers')}
							className={`px-6 py-2 rounded-full font-semibold transition-all duration-300 ${
								activeTab === 'risers' ? 'bg-green-400/20 text-green-400 border-2 border-green-400/20' : 'bg-white/5 border-2 border-white/10 hover:border-white/20'
							}`}
						>
							Top Risers
						</button>
						<button
							onClick={() => setActiveTab('fallers')}
							className={`px-6 py-2 rounded-full font-semibold transition-all duration-300 ${
								activeTab === 'fallers' ? 'bg-red-400/20 text-red-400 border-2 border-red-400/20' : 'bg-white/5 border-2 border-white/10 hover:border-white/20'
							}`}
						>
							Top Fallers
						</button>
					</div>
				</div>
			</div>

			{/* Content */}
			<div className='max-w-7xl mx-auto w-full'>
				{isLoading ? (
					<div className='flex items-center justify-center h-40'>
						<Loader2 className='h-8 w-8 animate-spin text-gray-400' />
					</div>
				) : error ? (
					<div className='text-center text-red-400 p-4 bg-red-400/10 rounded-lg'>{error}</div>
				) : (
					<div className='flex flex-col gap-3'>
						<CompanyList companies={activeTab === 'risers' ? marketData?.risers : marketData?.fallers} isRiser={activeTab === 'risers'} handleClick={handleClickRiserFaller} />
					</div>
				)}
			</div>
		</div>
	)
}

const CompanyCard: React.FC<CompanyCardProps> = ({ company, isRiser, handleClick }) => {
	const latestNews = company.recentNews?.[0]
	const date = latestNews?.date ? new Date(latestNews.date) : new Date()
	const formattedDate = isToday(date) ? 'Today' : formatDateDistance(date)

	return (
		<div
			className='bg-gray-800 border-2 border-white/10 rounded-lg hover:border-white/20 transition-all duration-300 cursor-pointer overflow-hidden'
			onClick={() => handleClick(company.ticker)}
		>
			<div className='p-4'>
				<div className='flex justify-between items-start mb-2'>
					<div>
						<h3 className='text-lg font-semibold text-white'>{company.name}</h3>
						<p className='text-sm text-gray-400'>{company.ticker}</p>
					</div>
					<span
						className={`text-xs border-4 font-extrabold px-3 py-1 rounded-full shadow-lg flex items-center min-w-[60px] justify-center
              ${isRiser ? 'border-green-500/60 text-white' : 'border-red-400/60 text-white'}`}
					>
						{company.changePercent}
					</span>
				</div>

				<div className='flex flex-row justify-between gap-2 mb-4'>
					<div className='flex items-center'>
						<Coins className='w-4 h-4 mr-1 text-gray-400' />
						<span className='font-medium text-white'>{company.price}</span>
					</div>
					<div className='flex items-center'>
						<BarChart2 className='w-4 h-4 mr-1 text-gray-400' />
						<span className='font-medium text-white'>{company.volume}</span>
					</div>
				</div>

				{latestNews?.generatedData?.headline && (
					<div className='bg-gray-700 p-3 rounded-md'>
						<div className='flex items-center text-xs text-gray-400 mb-1'>
							<Clock className='w-3 h-3 mr-1' />
							<span>{formattedDate}</span>
						</div>
						<p className='text-sm text-gray-200'>{latestNews.generatedData.headline}</p>
					</div>
				)}
			</div>
		</div>
	)
}

const CompanyList: React.FC<{
	companies?: RiserFallerCompany[]
	isRiser: boolean
	handleClick: (ticker: string) => void
}> = ({ companies = [], isRiser, handleClick }) => (
	<>
		{companies.map((company) => (
			<CompanyCard key={company.ticker} company={company} isRiser={isRiser} handleClick={handleClick} />
		))}
	</>
)

// Helper function for formatting dates
function formatDateDistance(date: Date): string {
	const now = new Date()
	const diffInSeconds = Math.floor((now.getTime() - date.getTime()) / 1000)

	const secondsInMinute = 60
	const secondsInHour = secondsInMinute * 60
	const secondsInDay = secondsInHour * 24

	if (diffInSeconds < secondsInMinute) {
		const seconds = diffInSeconds
		return `${seconds} second${seconds !== 1 ? 's' : ''} ago`
	} else if (diffInSeconds < secondsInHour) {
		const minutes = Math.floor(diffInSeconds / secondsInMinute)
		return `${minutes} minute${minutes !== 1 ? 's' : ''} ago`
	} else if (diffInSeconds < secondsInDay) {
		const hours = Math.floor(diffInSeconds / secondsInHour)
		return `${hours} hour${hours !== 1 ? 's' : ''} ago`
	} else {
		const days = Math.floor(diffInSeconds / secondsInDay)
		return `${days} day${days !== 1 ? 's' : ''} ago`
	}
}

export default MarketMovers
