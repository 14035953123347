import { gql } from '@apollo/client'
import { getServiceClient } from './base'
import { Bookmark, PaginatedBookmarks } from '../types/bookmark'

export async function addBookmark(bm: Bookmark): Promise<void> {
	try {
		const client = await getServiceClient('user')
		await client.mutate({
			mutation: gql`
                mutation {
                    addBookmark(
                        market: "${bm.market}", 
                        ticker: "${bm.ticker}", 
                        rnsId: "${bm.rnsId}",
                        newsDate: "${bm.newsDate}"
                    )
                }
            `,
		})
	} catch (error) {
		console.error(`Error adding bookmark`, error)
	}
	return
}

export async function removeBookmark(bm: Bookmark): Promise<void> {
	try {
		const client = await getServiceClient('user')
		await client.mutate({
			mutation: gql`
                mutation {
                    removeBookmark(
                        market: "${bm.market}", 
                        ticker: "${bm.ticker}", 
                        rnsId: "${bm.rnsId}"
                    )
                }
            `,
		})
	} catch (error) {
		console.error(`Error removing bookmark`, error)
	}
	return
}

export async function getUserBookmarks(
	page: number = 1,
	limit: number = 20,
	startDate?: string,
	endDate?: string,
	dateField?: 'newsDate' | 'bookmarkCreatedAt',
): Promise<PaginatedBookmarks> {
	try {
		const client = await getServiceClient('user')
		const resp = await client.query({
			query: gql`
				query GetUserBookmarks($page: Int!, $limit: Int!, $startDate: String, $endDate: String, $dateField: String) {
					getUserBookmarks(page: $page, limit: $limit, startDate: $startDate, endDate: $endDate, dateField: $dateField) {
						bookmarks {
							market
							rnsId
							ticker
							newsDate
							bookmarkCreatedAt
						}
						totalCount
						totalPages
						currentPage
					}
				}
			`,
			variables: {
				page,
				limit,
				startDate,
				endDate,
				dateField,
			},
		})
		return resp.data.getUserBookmarks
	} catch (error) {
		console.error(`Error getting bookmarks`, error)
		return {
			bookmarks: [],
			totalCount: 0,
			totalPages: 0,
			currentPage: 1,
		}
	}
}
