import { useMemo, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { getBrokerTargets, getCompany } from '../../services/company'
import { BrokerTargets, Company } from '../../types/company'
import { CompanyPanel } from '../../components/organisms/CompanyPanel/CompanyPanel'
import NewsView from '../../components/organisms/NewsView/NewsView'
import { listCompanyRns } from '../../services/rns'
import { Rns } from '../../types/rnsList'
import { ProgressSpinner } from '../../components/atoms/ProgressSpinner'
import { getUserBookmarks } from '../../services/user'
import { isRegistered } from '../../services/auth'
import TabMenu from '../../components/molecules/TabMenu/TabMenu'
import { News, Target } from '../../components/atoms/Icons/Icons'
import BrokerTargetView from '../../components/organisms/BrokerTargetView/BrokerTargetView'

const CompanyView: React.FC = () => {
	const { market = '', ticker = '' } = useParams<{ market: string; ticker: string }>()
	const location = useLocation()
	const queryParams = new URLSearchParams(location.search)
	const newsId = queryParams.get('newsId') || ''

	const [companyNewsDate, setCompanyNewsDate] = useState<any[]>()
	const [isLoading, setIsLoading] = useState(true)
	const [newsList, setNewsList] = useState<Rns[]>([])
	const [newsPaging, setNewsPaging] = useState<any>()
	const [company, setCompany] = useState<Company>()
	const [tabbedView, setTabbedView] = useState<string>('news')
	const [usersBookmarkSet, setUsersBookmarkSet] = useState<Set<string>>()
	const [brokerTargets, setBrokerTargets] = useState<Array<BrokerTargets>>()

	useMemo(() => {
		getCompany(market, ticker).then((company: Company) => {
			setCompany(company)
			setIsLoading(false)
		})
		listCompanyRns(market, ticker, 30).then((resp) => {
			setNewsList(resp.items)
			setNewsPaging(resp.paging)
		})
		if (isRegistered()) {
			getUserBookmarks().then((resp) => {
				const bookmarkIds = new Set(resp.bookmarks?.map((bookmark) => bookmark.rnsId))
				setUsersBookmarkSet(bookmarkIds)
			})
		} else {
			setUsersBookmarkSet(new Set())
		}
		setBrokerTargets(undefined)
		getBrokerTargets(ticker).then((bt) => {
			setBrokerTargets(bt)
		})
	}, [ticker])

	useMemo(() => {
		if (newsList && newsList.length) {
			setCompanyNewsDate(
				newsList.map((news) => ({
					id: news.id,
					headline: news?.generatedData?.headline || news.headline,
					date: news.date,
					sentiment: news?.generatedData?.sentimentScore,
				})),
			)
		}
	}, [newsList])

	const handleLoadMoreNewsButton = () => {
		if (newsPaging) {
			listCompanyRns(market, ticker, 10, newsPaging.lastKey).then((resp) => {
				setNewsList((prevNewsList) => [...prevNewsList, ...resp.items])
				setNewsPaging(resp.paging)
			})
		}
	}

	function handleTabClick(selected: any) {
		setTabbedView(selected)
	}

	function renderView() {
		switch (tabbedView) {
			case 'news':
				return (
					<NewsView
						market={market}
						ticker={ticker}
						newsId={newsId}
						newsList={newsList}
						bookmarks={usersBookmarkSet}
						clickLoadMoreNews={handleLoadMoreNewsButton}
						newsPaging={newsPaging}
					/>
				)
			case 'brokertargets':
				return <BrokerTargetView targets={brokerTargets} />
			default:
				return null
		}
	}

	if (isLoading) {
		return (
			<div className='h-screen bg-msDark'>
				<ProgressSpinner className='text-white' isPage={true} size='large' />
			</div>
		)
	}

	return (
		<div className='flex flex-col h-full font-sans text-white bg-msDark pt-[60px]'>
			<div className='h-auto bg-msDark pt-4 md:mx-4'>{company && <CompanyPanel company={company} companyNewsDate={companyNewsDate} />}</div>
			<div className='flex flex-row justify-center items-center h-auto text-white bg-msDark max-h-96 w-full'>
				<div className='flex justify-center items-center w-full px-2 md:w-1/2 md:mt-5 shadow-lg'>
					<TabMenu
						tabs={[
							{ id: 'news', label: 'news', icon: <News /> },
							{
								id: 'brokertargets',
								label: 'broker targets',
								icon: <Target />,
							},
						]}
						onTabClick={(e) => handleTabClick(e)}
					/>
				</div>
			</div>
			<div className='flex flex-row pt-4 w-screen h-screen bg-msDark shadow-xl'>{renderView()}</div>
		</div>
	)
}

export default CompanyView
