import { gql } from '@apollo/client'
import { getServiceClient } from './base'

export async function getRnsPrompt(rnsId: string): Promise<{ prompt: string; system: string; model: string; apiKey: string } | undefined> {
	try {
		const client = await getServiceClient('rns')
		const resp = await client.query({
			query: gql`
				query GetRnsPrompt {
					getRnsPrompt(rnsId: "${rnsId}") {
						prompt
						system
						model
                        apiKey
					}
				}
			`,
		})
		return resp.data.getRnsPrompt as { prompt: string; system: string; model: string; apiKey: string }
	} catch (error) {
		console.error(`Error getting Riser fallers`, error)
	}
	return undefined
}
